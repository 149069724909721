import {asCurrencyRounded} from '../utils';

export function getTaxes(items) {
  const taxes = {};
  (items ?? []).forEach((item) => {
    (item.tax?.components ?? []).forEach((tax) => {
      if (!taxes[tax.name]) {
        taxes[tax.name] = {
          tax: {
            name: tax.name,
            rate: tax.rate,
          },
          total: 0,
        };
      }

      taxes[tax.name].total += asCurrencyRounded(tax.rate * asCurrencyRounded(item?.subTotal) / 100);
    });
  });

  return Object.values(taxes).map((result) => ({...result, total: asCurrencyRounded(result.total)}));
}

export function getTaxTotal(items) {
  return asCurrencyRounded(getTaxes(items).reduce((acc, tax) => acc + asCurrencyRounded(tax.total), 0));
}
