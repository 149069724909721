import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSaveSalesDoc} from '../../../hooks/api';
import {CartPriceCalculator} from '../Models/CartPriceCalculator';
import {CartItemMap} from '../Models/CartItemMap';
import {useUser} from '../../../hooks';
import {SalesDocItem} from '../Models/SalesDoc';

export function useSalesDocCartState({salesStore, cartTemplate, cart: mainCartDoc, groupId}) {
  const {save: saveSalesDocApi, isSaving: cartSaving} = useSaveSalesDoc();
  const [cartDoc, setCartDoc] = useState(null);
  const {user} = useUser();
  const cartItemMapRef = useRef(new CartItemMap(salesStore));

  useEffect(() => {
    const template = cartTemplate ?? mainCartDoc;
    if (salesStore && template) {
      let doc = salesStore.createCart({
        template,
        priceCalculator: new CartPriceCalculator({salesStore, itemMap: cartItemMapRef.current}),
        withSurchargesDiscounts: !mainCartDoc,
      });
      if (mainCartDoc && groupId) {
        if (mainCartDoc.getFirstItemInGroup(groupId)) {
          // Add a product from the main cart into this cart
          cartItemMapRef.current.initGroupMap(groupId, mainCartDoc, mainCartDoc.priceCalculator.itemMap);
          doc = doc.addItems(mainCartDoc.getItemsInGroup(groupId).filter((item) => !item.isPlaceholder() && item.quantity > 0).map((item) => item.copyWith({}))).recalculate().notify();
        } else {
          // Adding a product to the cart for the first time, so create a mapping of all the rows
          // in the group and then add the rolled up items to the cart
          cartItemMapRef.current.initGroupMap(groupId);
          const items = salesStore
            .getPresentationRowIdsInGroup(groupId)
            .map((rowVariantId) => salesStore.getFirstVariantItem(rowVariantId))
            .filter((item) => item.rollupSellPrice && (item.isDecoration() || item.isAdditionalCost()));
          if (items.length > 0) {
            doc = cartItemMapRef.current.addItemsToCart(doc, items);
          }
        }
      }
      setCartDoc(doc.setNotify(setCartDoc));
    }
  }, [cartTemplate, groupId, mainCartDoc, salesStore]);

  const addItemToCart = useCallback(({item, quantity, color, size}) => {
    let items = [];
    if (item.isProductVariant()) {
      items.push(salesStore.getFirstVariantItem(item.variantId).copyWith({quantity, color, size, colors: undefined, sizes: undefined}));
    } else if (item.isDecoration()) {
      const decoration = salesStore.getFirstVariantItem(item.variantId);
      items.push(decoration);
      const setup = decoration.getSetupCostItem();
      if (setup) {
        items.push(setup);
      }
    } else if (item.isAdditionalCost()) {
      items.push(salesStore.getFirstVariantItem(item.variantId));
    }
    return cartItemMapRef.current.addItemsToCart(cartDoc, items);
  }, [cartDoc, salesStore]);

  const isInCart = useCallback((storeItem) => {
    const variantIdInCart = cartItemMapRef.current.getCartId(storeItem.variantId);
    return cartDoc?.items.some((item) => item.variantId === variantIdInCart);
  }, [cartDoc?.items]);

  const transferToCart = useCallback((cart) => {
    cart.priceCalculator.itemMap.addMappings(cartItemMapRef.current);
    const cartGroupId = cartDoc.items.filter((item) => !item.isPlaceholder())[0]?.groupId;
    const surchargeDiscountItem = cart.items.find(({applyAtCheckout, type}) => applyAtCheckout && type === SalesDocItem.Type.ADDITIONAL_COST);
    cart.deleteGroup(cartGroupId)
      .addItemsBefore(surchargeDiscountItem, cartDoc.items.filter((item) => !item.isPlaceholder() && item.quantity > 0))
      .recalculate()
      .notify();
  }, [cartDoc]);

  const clearCart = useCallback(async () => {
    let doc = salesStore.createCart({template: cartTemplate, priceCalculator: new CartPriceCalculator({salesStore, itemMap: cartItemMapRef.current})});
    setCartDoc(doc.setNotify(setCartDoc));
  }, [cartTemplate, salesStore]);

  const saveCart = useCallback(async () => {
    const res = await saveSalesDocApi({id: salesStore.guestToken, salesDoc: cartDoc.forApi()}, {successMessage: false});
    if (res) {
      setCartDoc((prevDoc) => prevDoc.copyWith({
        _id: res.salesDoc._id,
        number: res.salesDoc.number,
        guestToken: res.salesDoc.guestToken,
      }));
    }
    return res;
  }, [cartDoc, salesStore?.guestToken, saveSalesDocApi]);

  return useMemo(() => ({
    cartDoc,
    cartToken: user?._id ? cartDoc?._id : cartDoc?.guestToken,
    clearCart,
    saveCart,
    cartSaving,
    addItemToCart,
    cartItemMap: cartItemMapRef.current,
    isInCart,
    transferToCart,
  }), [cartDoc, user?._id, clearCart, saveCart, cartSaving, addItemToCart, isInCart, transferToCart]);
}
