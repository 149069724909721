import React from 'react';
import classNames from 'classnames';
import {Switch as MuiSwitch} from '@mui/material';
import {CaptionText} from '../Text';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import {Label} from './Label';

registerGlobalStyle('.switch-button', (theme) => ({
  '&:is(label)': {
    columnGap: theme.spacing(1),
    alignSelf: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    '.text-caption': {
      color: theme.colors.text.main,
      fontSize: '.875rem',
    }
  }
}));

export function Switch({className, checked, disabled, label, noWrapLabel, onChange, children}) {
  if (label || children) {
    return (
      <Label className={[className, 'switch-button']} disabled={disabled} noWrap={noWrapLabel}>
        <CaptionText>{label ?? children}</CaptionText>
        <MuiSwitch checked={checked} disabled={disabled} onChange={onChange}/>
      </Label>
    );
  } else {
    return <MuiSwitch className={classNames([className, 'switch-button'])} checked={checked} disabled={disabled} onChange={onChange}/>;
  }
}

Switch.propTypes = {
  className: HoopsPropTypes.className,
  checked: HoopsPropTypes.bool,
  disabled: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  noWrapLabel: HoopsPropTypes.bool,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
