import React from 'react';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {alphaBlend} from '../../utils';

registerGlobalStyle('.button-theme', () => ({
  '&>.button': {
    '&.nav-primary, &.nav-main, &.nav-positive': {
      backgroundColor: 'var(--theme-color) !important',
      borderColor: 'var(--theme-color) !important',
      '&:not(.disabled):hover': {
        backgroundColor: 'var(--theme-color-hover) !important',
        borderColor: 'var(--theme-color-hover) !important',
      },
      '&.disabled': {opacity: .6},
    },
    '&.nav-standard': {
      backgroundColor: 'transparent !important', //theme.colors.palette.white,
      borderColor: 'var(--theme-color) !important', //theme.colors.palette.blue,
      color: 'var(--theme-color) !important', //theme.colors.text.highlight,
      '&:not(.disabled):hover': {
        backgroundColor: 'var(--theme-color-lightest) !important', //theme.colors.background.hover,
        borderColor: 'var(--theme-color-hover) !important', //theme.colors.palette.blueHover,
        color: 'var(--theme-color-hover) !important', //theme.colors.text.highlightDark,
      },
    },
    '&.nav-minor': {
      backgroundColor: 'transparent !important',
      borderColor: 'transparent !important',
      color: 'var(--theme-color) !important',
      '&:not(.disabled):hover': {
        backgroundColor: 'var(--theme-color-lightest) !important',
        borderColor: 'transparent !important',
        color: 'var(--theme-color-hover) !important',
      },
    },
    '&.action-standard': {
      backgroundColor: 'transparent !important',
      borderColor: 'transparent !important',
      color: 'var(--theme-color) !important',
      '&:not(.disabled):hover': {
        backgroundColor: 'var(--theme-color-lightest) !important',
        borderColor: 'transparent !important',
        color: 'var(--theme-color-hover) !important',
      },
    },
  }
}));

export function ButtonTheme({color, children}) {
  color = color ?? '#FF0000';
  return (
    <div className={'button-theme'} style={{
      '--theme-color': color,
      '--theme-color-hover': alphaBlend(color, '#8080804C', '#'),
      '--theme-color-lightest': `${color}0D`
    }}>
      {children}
    </div>
  );
}

ButtonTheme.propTypes = {
  color: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
