import React, {useCallback} from 'react';
import {DropZone} from './DropZone';
import {MaxFileSize} from '../../utils';
import {Row} from '../Layout';
import {FileSelect} from './FileSelect';
import {HoopsPropTypes} from '../utils';
import {ImageThumbnail} from '../Basic';
import {registerGlobalStyle} from '../../theme';
import {BodyText, HeadingText} from '../Text';
import {useDeviceDetect} from '../../hooks/useDeviceDetect';

registerGlobalStyle('.image-upload-manager', (theme) => ({
  '.image-selector': {
    alignSelf: 'start',
    position: 'relative',
    padding: '10px 0',
    columnGap: theme.spacing(0.5),
    rowGap: theme.spacing(3),
  },
  '.image-thumbnail': {
    width: 100,
    height: 100,
  },
  '.file-select:last-child': {
    width: 100,
    height: 100,
  },
  '.file-select:first-child': {
    width: '100%',
    height: 100,
  },
  '.text-heading': {
    width: 'unset',
    lineHeight: 1.3,
  }
}));

export function ImageAndFileUploadManager({acceptAny, acceptPDF, dropZoneFirst, hoverOutline, hoverZoom, files, maxSize, multiSelect, uploadCache, value, onChange, onUploadComplete, children}) {
  const {isDesktop} = useDeviceDetect();
  const handleDropFile = useCallback((droppedFiles) => {
    droppedFiles.forEach(async (file) => {
      const url = await uploadCache.uploadFile(file);
      if (url && onUploadComplete) {
        if (!multiSelect) {
          onUploadComplete(url);
        } else {
          onUploadComplete([...value ?? [], url]);
        }
      }
    });
  }, [multiSelect, onUploadComplete, uploadCache, value]);

  const handleThumbnailClick = useCallback((image) => {
    if (!multiSelect) {
      onChange?.(image);
    } else {
      const index = value?.indexOf(image);
      if (index >= 0) {
        onChange?.(value.toSpliced(index, 1));
      } else {
        onChange?.([...value ?? [], image]);
      }
    }
  }, [multiSelect, onChange, value]);

  maxSize = maxSize ?? MaxFileSize.Max2Meg;
  const accept = acceptAny ? '' : (`image/*${acceptPDF ? ',application/pdf' : ''}`);

  return (
    <DropZone className={'image-upload-manager'} accept={accept} multi={multiSelect} onDrop={handleDropFile} maxSize={maxSize}>
      <Row className={'image-selector'} wrap>
        {dropZoneFirst &&
          <>
            <FileSelect accept={accept} onChange={handleDropFile} progress={uploadCache.progress} maxSize={maxSize}>
              <HeadingText x20>{isDesktop ? 'Drag and Drop' : `Upload ${acceptAny ? 'Files' : 'Images'}`}</HeadingText>
              {isDesktop &&
                <BodyText>{`your ${acceptAny ? 'files' : 'images'} here or click to upload`}</BodyText>
              }
            </FileSelect>
            {children}
          </>
        }
        {files.map((image) => (
          <ImageThumbnail
            key={image}
            selected={value === image || value?.includes?.(image)}
            hoverOutline={hoverOutline}
            hoverZoom={hoverZoom}
            imageUrl={image}
            uploaded={image && uploadCache.urls.includes(image)}
            onClick={() => handleThumbnailClick(image)}
            size={100}
          />
        ))}
        {!dropZoneFirst &&
          <>
            {children}
            <FileSelect accept={accept} onChange={handleDropFile} progress={uploadCache.progress} maxSize={maxSize} />
          </>
        }
      </Row>
    </DropZone>
  );
}

ImageAndFileUploadManager.propTypes = {
  acceptAny: HoopsPropTypes.bool,
  acceptPDF: HoopsPropTypes.bool,
  dropZoneFirst: HoopsPropTypes.bool,
  hoverOutline: HoopsPropTypes.bool,
  hoverZoom: HoopsPropTypes.bool,
  files: HoopsPropTypes.arrayOfString,
  maxSize: HoopsPropTypes.oneOf(Object.values(MaxFileSize)),
  multiSelect: HoopsPropTypes.bool,
  value: HoopsPropTypes.oneOfType([HoopsPropTypes.string, HoopsPropTypes.arrayOfString]),
  uploadCache: HoopsPropTypes.object,
  onChange: HoopsPropTypes.func,
  onUploadComplete: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
