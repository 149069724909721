import {useCallback, useEffect, useMemo, useState} from 'react';
import {SalesDoc} from '../Models/SalesDoc';
import {useGetSalesDoc, useSalesDocCache} from '../../../hooks/api';
import {useCompany, useUser} from '../../../hooks';
import {GuestPriceCalculator} from '../Models/GuestPriceCalculator';

export function useSalesDocGuestState({guestToken, hosting}) {
  const {data: {salesDoc: apiSalesDoc, company: companyData, template: cartTemplate}, isLoading: salesDocLoading, refetch: refetchSalesDoc} =
    useGetSalesDoc(hosting?.hostname ?? guestToken, hosting?.path ? {path: hosting.path} : undefined);
  const {updateCache: updateSalesDocCache} = useSalesDocCache();
  const [salesDoc, _setSalesDoc] = useState(null);
  const {user} = useUser();
  const {company} = useCompany(salesDoc?.companyTradingEntityId ?? salesDoc?.customer?.companyTradingEntityId, companyData);

  // Whenever the SalesDoc is set, it will be bound to the salesDoc state
  const setSalesDoc = useCallback((newSalesDoc) => {
    _setSalesDoc(newSalesDoc.setNotify(setSalesDoc));
  }, []);

  // This effect handles the initial load, loading a SalesDoc from the server
  useEffect(() => {
    if (apiSalesDoc) {
      const newSalesDoc = SalesDoc.fromApi(apiSalesDoc, {priceCalculator: new GuestPriceCalculator()});
      _setSalesDoc(newSalesDoc.setNotify(setSalesDoc));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSalesDoc]);

  const updateCache = useCallback((res) => {
    const salesDocWithUpdates = {...apiSalesDoc, ...res.salesDoc};
    updateSalesDocCache({id: guestToken, salesDoc: salesDocWithUpdates});
  }, [apiSalesDoc, guestToken, updateSalesDocCache]);

  return useMemo(() => ({
    cartTemplate,
    company,
    isLoading: salesDocLoading,
    refetchSalesDoc,
    salesDoc,
    salesDocToken: user?._id ? salesDoc?._id : salesDoc?.guestToken,
    updateCache,
  }), [cartTemplate, company, refetchSalesDoc, salesDoc, salesDocLoading, updateCache, user?._id]);
}
