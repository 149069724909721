import {
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import {find, get, indexOf, omit} from 'lodash';
import React, {useContext, useEffect} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {HoopsModalContext} from '../../componentsOld/shared/HoopsModalContext';
import green from '../../ui/theme/green';
import {createAutomation, getAutomation, updateAutomation} from './actions';
import {AutomationAction} from './AutomationAction';
import AutomationEmailMessage from './AutomationEmailMessage';
import AutomationRadioCard from './AutomationRadioCard';
import AutomationTriggerConfigCreated from './AutomationTriggerConfigCreated';
import AutomationTriggerConfigOwnerAssigned from './AutomationTriggerConfigOwnerAssigned';
import AutomationTriggerConfigStatusChange from './AutomationTriggerConfigStatusChange';
import {AutomationTriggerConfigCustomStatusChange} from './AutomationTriggerConfigCustomStatusChange';
import AutomationTriggerConfigUserAssigned from './AutomationTriggerConfigUserAssigned';
import {capitalize} from '../../utils';
import AutomationTriggerConfigPurchaseOrder from './AutomationTriggerConfigPurchaseOrder';
import AutomationTriggerConfigProofs from './AutomationTriggerConfigProofs';
import {AutomationTriggerAccountingConfigStatusChangeJob} from './AutomationTriggerConfigAccountingStatusChangeJob';
import {AppAccess, permissionCheck, SubscriptionTypes} from '../../componentsHoops';
import AutomationTriggerConfigProofsActioned from './AutomationTriggerConfigProofsActioned';
import {Alert, AlertTitle} from '@mui/material';
import {useGetViews} from '../../hooks/api';

// this array helps to display the correct triggers and also stores the companyEntityType which is the name of the status stored in company which can be different
const entityAutomationTypesArray = [
  {
    entity: 'quote',
    triggers: [
      'statusChanged',
      'created',
      'ownerAssigned'
    ],
    companyEntityType: 'quote'
  },
  {
    entity: 'salesdoc',
    triggers: [
      'statusChanged',
    ],
    companyEntityType: 'quote'
  },
  {
    entity: 'job',
    triggers: [
      'statusChanged',
      'purchaseOrder',
      'proofs',
      'proofsActioned',
      'accountingStatus'
    ],
    companyEntityType: 'job'
  },
  {
    entity: 'task',
    triggers: [
      'statusChanged',
      'created',
      'userAssigned'
    ],
    companyEntityType: 'task'
  },
  {
    entity: 'file',
    triggers: [
      'statusChanged'
    ],
    companyEntityType: 'file'
  },
  {
    entity: 'schedule',
    triggers: [
      'statusChanged',
      'created'
    ],
    companyEntityType: 'schedule'
  },
];

const AutomationTriggerConfig = ({trigger, entityType, viewData}) => {
  switch (trigger) {
    case 'accountingStatus':
      return <AutomationTriggerAccountingConfigStatusChangeJob entityAutomationTypesArray={entityAutomationTypesArray}/>;
    case 'statusChanged':
      return (entityType === 'job' || entityType === 'salesdoc')
        ? <AutomationTriggerConfigCustomStatusChange viewData={viewData}/>
        : <AutomationTriggerConfigStatusChange entityAutomationTypesArray={entityAutomationTypesArray}/>;
    case 'purchaseOrder':
      return entityType === 'job' && <AutomationTriggerConfigPurchaseOrder/>;
    case 'proofsActioned':
      return entityType === 'job' && <AutomationTriggerConfigProofsActioned/>;
    case 'proofs':
      return entityType === 'job' && <AutomationTriggerConfigProofs/>;

    case 'created':
      return <AutomationTriggerConfigCreated/>;

    case 'ownerAssigned':
      return <AutomationTriggerConfigOwnerAssigned/>;

    case 'userAssigned':
      return <AutomationTriggerConfigUserAssigned/>;

    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.colors.grey.ultraLight,
  },
  wizardContainer: {padding: theme.spacing(3),},
  selectClass: {
    fontSize: 16,
    padding: theme.spacing(1.4),
    minWidth: 213,
    backgroundColor: theme.colors.white,
  },
  cancelBtn: {backgroundColor: theme.colors.white,}
}));

const AutomationEditModal = () => {
  const {activeId, setActiveId, open, handleClose} = useContext(HoopsModalContext);
  const classes = useStyles();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  const {handleSubmit, reset, control, errors, watch, getValues} = methods;

  const automation = watch();
  const entityType = automation?.entityType || null;
  const {data: {views: viewData}} = useGetViews(entityType && (entityType === 'job' ? 'jobs' : 'salesdocs'));
  const showTriggers = !!entityType;
  const trigger = get(automation, 'trigger');
  const showConfig = !!trigger;
  const hasAction = !!get(automation, 'action');
  const via = get(automation, 'via');
  const updateFieldPath = get(automation, 'updateFieldPath');
  const showLast = !!(via || updateFieldPath);
  const showEmailContainer = !!get(automation, 'recipients[0]');
  const entityObj = find(entityAutomationTypesArray, {'entity': entityType});
  const triggerArray = entityObj ? entityObj.triggers : [];
  const onSubmit = async (data) => {
    const config = {
      action: data.action,
      statusType: data.statusType,
      status: data.status,
      via: data.via,
      recipients: data.recipients,
      subject: data.subject,
      body: data.body,
      updateFieldPath: data.updateFieldPath,
      howLong: data.howLong,
      updateFieldValue: data.updateFieldValue,
    };
    omit(data, ['action', 'statusType', 'status', 'recipients', 'subject', 'body', 'via', 'updateFieldPath', 'howLong', 'updateFieldValue']);
    data = {...data, config};

    await activeId
      ? dispatch(updateAutomation(activeId, data))
      : dispatch(createAutomation(data));

    handleClose();
    setForm();
    setActiveId(null);
  };

  // we are resetting fields and allowing the user to set new data
  const setForm = (values = {}) => {
    reset({entityType: '', trigger: '', action: '', recipients: [], updateFieldPath: '', updateFieldValue: '', ...values});
  };

  const onCancel = () => {
    handleClose();
    !activeId && setForm();
    setActiveId(null);
  };

  useEffect(() => {
    if (activeId) {
      dispatch(getAutomation(activeId)).then(({config, ...aut}) => {
        reset({...aut, ...config});
      });
    } else {
      setForm({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId, dispatch, reset]);

  if (!open) {
    return null;
  }

  return (
    <>
      <FormProvider {...methods} >
        <Dialog
          open={open}
          fullScreen
          onClose={handleClose}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='row' justifyContent='start' alignItems='flex-start' className={classes.titleContainer} spacing={3}>
              <Grid item xs={12}>
                <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
                  <Grid item component={'div'}>
                    <Typography variant='h4'>{activeId ? 'Edit' : 'Add'} Automation</Typography>
                  </Grid>
                  <Grid item>
                    <Button variant={'outlined'} color={'primary'} className={classes.cancelBtn} onClick={onCancel}>Cancel</Button>
                    <MuiThemeProvider theme={green}>
                      <Button variant={'contained'} color={'primary'} type={'submit'} style={{marginLeft: 16}}>
                        Save
                      </Button>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider variant='fullWidth'/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h5' gutterBottom>Select Entity</Typography>
                <FormControl variant='outlined'>
                  <Controller
                    name='entityType'
                    control={control}
                    render={({onBlur, onChange, value}) =>
                      <Select
                        inputProps={{className: classes.selectClass}}
                        onBlur={onBlur}
                        onChange={(event) => {
                          onChange(event.target.value);
                          const config = getValues();
                          //to preserve at least the following data. and reset rest
                          setForm({
                            name: config && config.name,
                            subject: config && config.subject,
                            body: config && config.body,
                            entityType: event.target.value
                          });
                        }}
                        value={value ? value : ''}
                        displayEmpty
                      >
                        <MenuItem value='' disabled>Select</MenuItem>
                        <MenuItem value='job'>JobBoard</MenuItem>
                        {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) &&
                          <MenuItem value='salesdoc'>SalesBoard</MenuItem>
                        }
                        {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs], disallowedAppAccess: [AppAccess.DisallowOldQuoting]}) &&
                          <MenuItem value='quote'>Classic Quote</MenuItem>
                        }
                        {/*<MenuItem value="purchaseOrder">Purchase Order</MenuItem>*/}
                        {/*<MenuItem value="task">Task</MenuItem>*/}
                        {/*<MenuItem value="proof">Proof</MenuItem>*/}
                        {/*<MenuItem value="file">File</MenuItem>*/}
                        {/*<MenuItem value="schedule">Schedule</MenuItem>*/}
                      </Select>
                    }
                  />
                </FormControl>
              </Grid>

              {showTriggers &&
                <>
                  <Grid item xs={12}>
                    <Typography variant='h5' gutterBottom>Select Automation Type </Typography>
                    <Controller
                      as={
                        <RadioGroup row>
                          {indexOf(triggerArray, 'statusChanged') > -1 &&
                            <AutomationRadioCard alwaysSelected={entityType === 'quote'} title='Status Change' value='statusChanged'
                                                 description={<>When a <b>status is changed</b>, this logic will <b>trigger an action</b> such as notifying your
                                                   customer.</>}/>}
                          {indexOf(triggerArray, 'purchaseOrder') > -1 && permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) &&
                            <AutomationRadioCard title='Job Purchase Orders' value='purchaseOrder'
                                                 description={<>When the <b>status</b> the <b>of Purchase Orders</b> for a job update, this logic will trigger
                                                   a <b>Job Status Change</b>.</>}/>}
                          {indexOf(triggerArray, 'proofs') > -1 && <AutomationRadioCard title='Proofs' value='proofs'
                                                                                        description={<>When the <b>status</b> the of <b>Proof(s)</b> change,
                                                                                          this logic will trigger a <b>Job Status Change</b>.</>}/>}
                          {/* {indexOf(triggerArray, 'proofsActioned') > -1 && <AutomationRadioCard title='Proofs Reminder' value='proofsActioned' description={<>if <b>proofs have not been actioned</b> whithin a period of time, this logic will <b>trigger a notification</b>.</>} />} */}
                          {indexOf(triggerArray, 'accountingStatus') > -1 && permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) &&
                            <AutomationRadioCard title='Accounting Status' value='accountingStatus'
                                                 description={<>When an <b>Invoice is created</b> or the <b>Accounting Status</b> changes, this logic will
                                                   update the <b>Job Status</b>.</>}/>}
                          {/*{indexOf(triggerArray, "created") > -1 && <AutomationRadioCard title="Created" value="created" description={<>When the <b>entity is created</b>, this logic will <b>trigger an action</b> such as a notification.</>} />}*/}
                          {/*{indexOf(triggerArray, "ownerAssigned") > -1 && <AutomationRadioCard title="Owner Assigned" value="ownerAssigned" description={<>When an <b>owner is assigned</b>, this logic will <b>trigger an action</b> such as a notification.</>} />}*/}
                          {/*{indexOf(triggerArray, "userAssigned") > -1 && <AutomationRadioCard title="Assigned" value="userAssigned" description={<>When a <b>user is assigned</b>, this logic will <b>trigger an action</b> such as a notification.</>} />}*/}
                        </RadioGroup>
                      }
                      name='trigger'
                      control={control}
                    />
                  </Grid>

                </>
              }
            </Grid>

            <Grid container direction='row' justifyContent='center' alignItems='flex-start' className={classes.wizardContainer} spacing={3}>
              {showTriggers && showConfig && trigger === 'proofsActioned' &&
                <Grid item xs={8}>
                  <Alert severity='info'>
                    <AlertTitle>Important Notice</AlertTitle>
                    There is a + - 30 minutes threshold on time-based automations. Example: if your automation is set for 2 hours, the automation will run
                    within the window between 1 hour, 30 minutes and 2 hours, 30 minutes.
                  </Alert>
                </Grid>
              }
              {showConfig &&
                <>
                  <Grid item xs={12} container justifyContent='center'>
                    <Controller
                      as={<TextField variant='outlined' margin='dense' size='small' style={{minWidth: 566}} placeholder='Automation Name'
                                     helperText={errors.name && 'Name is required'} error={errors.name}/>}
                      name='name'
                      control={control}
                      rules={{required: true}}
                    />
                  </Grid>

                  <Grid item xs={12} container justifyContent='center'>
                    <AutomationTriggerConfig
                      trigger={automation.trigger}
                      entityType={automation.entityType}
                      viewData={viewData}
                    />
                  </Grid>

                  <Grid item xs={12} container justifyContent='center'>
                    <AutomationAction showLast={showLast} hasAction={hasAction} viewData={viewData}/>
                  </Grid>
                </>
              }
              {showEmailContainer &&
                <Grid item xs={12} container justifyContent='center'>
                  <AutomationEmailMessage type={via} entity={capitalize(entityType)}/>
                </Grid>
              }
            </Grid>
          </form>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AutomationEditModal;
