import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';
import {HoopsPropTypes} from '../../componentsLib';

export const FeatureFlags = featureFlags;

// Checks access and either show or hides children
export function FeatureFlag({allow, disallow, children}) {
  if (allow && disallow && (!featureFlagEnabled(allow) || featureFlagEnabled(disallow))) {
    return false;
  }

  if (allow && featureFlagEnabled(allow)) {
    return children;
  }

  if (disallow && !featureFlagEnabled(disallow)) {
    return children;
  }

  return false;
}

FeatureFlag.propTypes = {
  allow: HoopsPropTypes.object,
  disallow: HoopsPropTypes.object,
  children: HoopsPropTypes.children,
};
