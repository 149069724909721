import React, {createElement, isValidElement} from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';

export function Decorator({className, decorator, children}) {
  children = children ?? decorator;

  if (!children) {
    return null;
  }

  if (isValidElement(children)) {
    return children;
  }

  if (typeof children === 'string') {
    return <span className={classNames(className, 'material-icons notranslate')}>{children}</span>;
  }

  return createElement(children, {className: classNames(className)});
}

Decorator.propTypes = {
  className: HoopsPropTypes.className,
  decorator: HoopsPropTypes.decorator,
  children: HoopsPropTypes.decorator,
};
