import {Grid, Link as MuiLink, makeStyles, Paper, Table, TableContainer, Typography} from '@material-ui/core';
import {get} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {resetActivePo} from '../../../actions/purchaseOrders';
import {PURCHASE_ORDER_STATUSES} from '../../../constants/status';
import AutomationIconButton from '../../../modules/automation/AutomationIconButton';
import {GQL_PURCHASE_ORDER_PAGINATION} from '../../../queries/purchaseOrders';
import {hoopsQueryStyles} from '../../../ui/theme/hoops';
import {featureFlagEnabled, featureFlags} from '../../../utils/featureFlag';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryFilterChips, HoopsQueryFilterDropMenu, HoopsQueryFilterList, HoopsQueryFilterSearch, HoopsQueryFilterUser, HoopsQueryFilterVendor, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../../HoopsQuery';
import HoopsQueryTableCellDateTime from '../../HoopsQuery/HoopsQueryTableCellDateTime';
import HoopsQueryTableConfig from '../../HoopsQuery/HoopsQueryTableConfig';
import StatusChangerCell from '../../Statuses/StatusChangerCell';
import PurchaseOrdersActionMenu from '../PurchaseOrdersActionMenu';

const useStyles = makeStyles((theme) => ({
  ...hoopsQueryStyles,
  greyText: {color: theme.colors.grey.main},
  orangeText: {color: theme.colors.orange},
  redText: {color: theme.colors.red},
}));

const filterListVendor = [{
  key: 'vendorId',
  type: 'array',
  options: [],
  label: 'Vendor',
  component: (props) => <HoopsQueryFilterVendor {...props} />
}];

const filterListStatus = [{
  key: 'status',
  type: 'array',
  options: [],
  label: 'Status',
  component: (props) => <HoopsQueryFilterList {...props} options={PURCHASE_ORDER_STATUSES.map(({name}) => ({value: name, label: name}))} />
}];

const filterListUser = [{
  key: 'createdById',
  type: 'array',
  options: [],
  label: 'User',
  component: (props) => <HoopsQueryFilterUser {...props} />
}];

export const PurchaseOrderManyTable = ({
  handleNewPurchaseOrder = () => null,
  fixedFilter = {},
  queryStateId = 'purchaseOrderMany'
}) => {
  const dispatch = useDispatch();
  const companySelector = (state) => state.companyReducer.company;
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const company = useSelector(companySelector);
  const currencySymbol = useSelector(currencySymbolSelector);
  const platform = get(company, 'accountingPlatform.platform', null);
  const classes = useStyles();

  // handle due date styling
  const dueDateClass = (dueDate) => {
    const now = moment();
    const daysToDueDate = now.diff(dueDate, 'days');

    if (daysToDueDate > -7 && daysToDueDate < 0) { return classes.orangeText; }
    if (daysToDueDate > 0) { return classes.redText; }
    return classes.greyText;
  };

  const columns = [{
    label: 'PO #',
    field: 'number',
    sortable: true,
    sortKey: 'NUMBER',
    render: (rowData) => (
      <MuiLink
        component={Link}
        underline={'none'}
        color={'primary'}

        to={`/purchase-orders/${rowData._id}/view`}
        onClick={() => dispatch(resetActivePo())}
        style={rowData.isMasterPurchaseOrder && {fontWeight: 'bold',}}
      >
        {rowData.number}
      </MuiLink>
    ),
    columnStyle: {minWidth: 120, width: 120, whiteSpace: 'nowrap'}
  }, {
    label: 'Merged PO #',
    field: 'masterPurchaseOrder.number',
    render: (rowData) => (
      <MuiLink
        component={Link}
        underline={'none'}
        color={'primary'}
        to={`/purchase-orders/${rowData._id}/view`}
        style={rowData.isMasterPurchaseOrder && {fontWeight: 'bold',}}
        onClick={() => dispatch(resetActivePo())}
      >
        {rowData.isMasterPurchaseOrder ? rowData.number : get(rowData, 'masterPurchaseOrder.number', [])}
      </MuiLink>
    ),
    columnStyle: {minWidth: 80, width: 80, whiteSpace: 'inherit'}
  }, {
    label: 'Job #',
    field: 'number',
    render: (rowData) => {
      const linkedJobs = get(rowData, 'linkedJobs', null);
      return Boolean(linkedJobs.length) && (
        <MuiLink
          component={Link}
          underline={'none'}
          color={'primary'}
          to={`/jobs/${linkedJobs[0]._id}/details`}
          onClick={() => dispatch(resetActivePo())}
        >
          {linkedJobs[0].number}
        </MuiLink>
      );
    },
    columnStyle: {minWidth: 80, width: 80}
  }, {
    label: 'SalesDoc #',
    field: 'number',
    render: (rowData) => {
      const linkedJobs = get(rowData, 'linkedJobs.length', null);
      const salesDoc = get(rowData, 'linkedJobs[0].quote', []);
      return Boolean(linkedJobs) && salesDoc && (
        <MuiLink
          component={Link}
          underline={'none'}
          color={'primary'}
          to={`/sales/${salesDoc.number}`}
          onClick={() => dispatch(resetActivePo())}
        >
          {salesDoc.number}
        </MuiLink>
      );
    },
    columnStyle: {minWidth: 80, width: 80, whiteSpace: 'nowrap'}
  }, {
    label: 'Quote #',
    field: 'number',
    render: (rowData) => {
      const linkedJobs = get(rowData, 'linkedJobs.length', null);
      const quote = get(rowData, 'linkedJobs[0].quote', []);
      return Boolean(linkedJobs) && quote && (
        <MuiLink
          component={Link}
          underline={'none'}
          color={'primary'}
          to={`/quotes/${quote._id}/view`}
          onClick={() => dispatch(resetActivePo())}
        >
          {quote.number}
        </MuiLink>
      );
    },
    columnStyle: {minWidth: 80, width: 80, whiteSpace: 'nowrap'}
  }, {
    label: 'Title',
    field: 'title',
    sortable: true,
    sortKey: 'TITLE',
    render: (rowData) => (
      <MuiLink
        component={Link}
        underline={'none'}
        color={'primary'}
        to={`/purchase-orders/${rowData._id}/view`}
        onClick={() => dispatch(resetActivePo())}
        className={classes.typographyRestrictTwoLines}
      >
        {rowData.title}
      </MuiLink>
    ),
    columnStyle: {minWidth: 220}
  }, {
    label: 'Reference Number',
    sortable: false,
    render: (rowData) => <Typography variant={'body2'} noWrap style={{width: 130}}>{rowData.customReference}</Typography>,
    columnStyle: {minWidth: 140, width: 140}
  }, ...(platform !== 'XERO' ? [] : [{
    label: 'Accounting PO #',
    field: 'accountingXero.number',
    sortable: false,
    columnStyle: {minWidth: 140, width: 140, whiteSpace: 'nowrap'}
  }]), ...(platform !== 'QUICKBOOKS' ? [] : [{
    label: 'Accounting PO #',
    field: 'accountingQBO.number',
    sortable: false,
    columnStyle: {minWidth: 140, width: 140, whiteSpace: 'nowrap'}
  }]), {
    label: 'Vendor',
    render: (rowData) => {
      if (!rowData.vendor) {
        return '-';
      }
      return (
        <Typography variant={'body2'} noWrap>{`${get(rowData, 'vendor.name', '')}${rowData.vendor.deletedAt ? ' (Deleted)' : ''}`}</Typography>
      );
    },
    cellStyle: {paddingTop: '8px !important', paddingBottom: 8, whiteSpace: 'nowrap'},
    columnStyle: {minWidth: 140, width: 140, whiteSpace: 'nowrap'}
  }, {
    label: 'Created By',
    render: (rowData) =>
      <>
        <Typography variant={'body2'} noWrap>{get(rowData, 'createdBy.fullName', '')}</Typography>
        <HoopsQueryTableCellDateTime value={rowData.createdAt} showTime={false} className={classes.greyText} variant='caption' />
      </>,
    columnStyle: {minWidth: 140, width: 140},
    cellStyle: {whiteSpace: 'nowrap'},
  }, {
    label: 'Deadline',
    field: 'deadlineAt',
    render: (rowData) => <HoopsQueryTableCellDateTime value={rowData.deadlineAt} showTime={false} className={dueDateClass(rowData.deadlineAt)} />,
    columnStyle: {minWidth: 140, width: 140}
  }, {
    label: 'Backorder Due Date',
    field: 'dueDate',
    render: (rowData) => <HoopsQueryTableCellDateTime value={rowData.dueDate} showTime={false} className={dueDateClass(rowData.deadlineAt)} />,
    columnStyle: {minWidth: 140, width: 140}
  }, {
    label: 'Total',
    headerStyle: {width: 64,},
    render: (rowData) => <>{currencySymbol}{numeral(rowData.total).format('0,0.00')}</>,
    columnStyle: {minWidth: 140, width: 140}
  }, {
    label: 'Status',
    align: 'center',
    render: (rowData) => <StatusChangerCell entityType='purchase_order' entityMapping='status' value={rowData.status} disabled size='small' />,
    cellStyle: {padding: 0, borderRight: '1px solid white', borderBottom: '1px solid white', whiteSpace: 'nowrap', position: 'relative'},
    columnStyle: {minWidth: 162, width: 162}
  }, {
    label: 'Sent Status',
    align: 'center',
    render: (rowData) => <StatusChangerCell entityType='purchase_order' entityMapping='sentStatus' value={rowData.sentStatus} disabled size='small' />,
    cellStyle: {padding: 0, borderRight: '1px solid white', borderBottom: '1px solid white', whiteSpace: 'nowrap', position: 'relative'},
    columnStyle: {minWidth: 162, width: 162}
  }, {
    label: 'Actions',
    align: 'center',
    render: (rowData) => <PurchaseOrdersActionMenu purchaseOrder={rowData} buttonType={'ICON'} platform={platform} />,
    columnStyle: {minWidth: 90, width: 90}
  }];

  const tableContainerElement = useRef();

  return (
    <>
      <HoopsQuery refetchStateId='purchaseOrder' queryStateId={queryStateId} columns={columns} gqlQuery={GQL_PURCHASE_ORDER_PAGINATION} resultField='purchaseOrderPagination' initialFilter={{}} fixedFilter={fixedFilter} initialSort={'NUMBER_DESC'} tableContainerElement={tableContainerElement}>
        <HoopsQueryContext.Consumer>
          {({items, sort, setSort, filter, setFilter, paging, setPaging, chips, setChips, hoopsQueryColumns, setHoopsQueryColumns, contentHeight}) => (<>
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
              <Grid item xs={6}>
                <HoopsQueryFilterDropMenu
                  label={'Vendor'}
                  filter={filter}
                  filterComponents={filterListVendor}
                  onFilterChange={setFilter}
                  chips={chips}
                  onChipsChange={setChips}
                />
                <HoopsQueryFilterDropMenu
                  label={'Status'}
                  filter={filter}
                  filterComponents={filterListStatus}
                  onFilterChange={setFilter}
                  chips={chips}
                  onChipsChange={setChips}
                />
                <HoopsQueryFilterDropMenu
                  label={'Created By'}
                  filter={filter}
                  filterComponents={filterListUser}
                  onFilterChange={setFilter}
                  chips={chips}
                  onChipsChange={setChips}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    {featureFlagEnabled(featureFlags.automations) && <AutomationIconButton />}
                  </Grid>
                  <Grid item>
                    <HoopsQueryTableConfig
                      columns={hoopsQueryColumns}
                      onColumnsChange={setHoopsQueryColumns}
                    />
                  </Grid>
                  <Grid item>
                    <HoopsQueryFilterSearch onChange={setFilter} filter={filter} placeholder={'Search'} chips={chips} onChipsChange={setChips} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid >
            <HoopsQueryFilterChips filter={filter} onFilterChange={setFilter} chips={chips} onChipsChange={setChips} />

            <TableContainer component={Paper} className={classes.tableContainer} ref={tableContainerElement} style={{height: `calc(100vh - ${contentHeight}px)`}}>
              <Table className={classes.table} stickyHeader>
                <HoopsQueryTableHeader hasCheckBox={false} columns={hoopsQueryColumns} onSort={setSort} sortString={sort} />
                <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={hoopsQueryColumns} stripedRows={true} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={columns} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={handleNewPurchaseOrder} />} />
              </Table>
            </TableContainer>
            <HoopsQueryPagination paging={paging} onPaging={setPaging} />

          </>)}
        </HoopsQueryContext.Consumer >
      </HoopsQuery >
    </>
  );
};
export default PurchaseOrderManyTable;
