import {Row} from '../../componentsLib/Layout';
import {Select, SelectItem} from '../../componentsLib/Pickers';
import {byLocaleCaseInsensitive} from '../../utils';
import {BodyText} from '../../componentsLib/Text';
import React from 'react';
import {HoopsPropTypes} from '../../componentsLib';

export function StatusFieldLabelPicker({className, fields, fieldPath, onChangeFieldName, statusLabel, onChangeStatusLabel}) {
  return (
    <Row className={[className, 'status-field-label-picker']} gap>
      <Select placeholder={'Choose Status Column'} value={fieldPath} onChange={onChangeFieldName}>
        {fields.toSorted((l, r) => byLocaleCaseInsensitive(l.title, r.title)).map(({path, title}) => (
          <SelectItem key={path} value={path}>
            <BodyText text={title}/>
          </SelectItem>
        ))}
      </Select>
      <Select placeholder={'Choose Status'} value={statusLabel} onChange={onChangeStatusLabel} enabled={!!fieldPath}>
        {fields.find(({path}) => path === fieldPath)?.options.map((option) => (
          <SelectItem key={option.statusLabel} value={option.statusLabel}>
            <BodyText text={option.statusLabel}/>
          </SelectItem>
        ))}
      </Select>
    </Row>
  );
}

StatusFieldLabelPicker.propTypes = {
  className: HoopsPropTypes.className,
  fieldPath: HoopsPropTypes.string,
  fields: HoopsPropTypes.arrayOfObject,
  statusLabel: HoopsPropTypes.string,
  onChangeFieldName: HoopsPropTypes.func,
  onChangeStatusLabel: HoopsPropTypes.func,
};
