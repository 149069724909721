import React from 'react';
import {AttentionConfirmDialog} from './AttentionConfirmDialog';
import Lottie from 'react-lottie';
import successAlertIcon from '../../assets/lotties/success-alert-icon.json';
import {BodyText} from '../Text';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.success-dialog', (theme) => ({
  '.MuiDialog-paper.MuiPaper-root': {maxWidth: 400},
  svg: {marginTop: 0},
  '.modal-dialog-content': {
    '&>.text-body': {
      fontSize: '1rem',
      padding: theme.spacing(0, 5, 2.5),
    },
  },
  '.actions': {justifyContent: 'center'}
}));

export const SuccessDialog = ({className, open, onClose, title, topText, bottomText}) => (
    <>
      {open &&
        <AttentionConfirmDialog
          title={title}
          onClose={onClose}
          onOk={onClose}
          okText={'Ok'}
          cancelText={null}
          className={['success-dialog', className]}
        >
          {topText && <BodyText text={topText} />}
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: successAlertIcon,
              rendererSettings: {preserveAspectRatio: 'xMidYMid slice'},
            }}
            height={200}
            width={200}
          />
          {bottomText && <BodyText text={bottomText} />}
        </AttentionConfirmDialog>
      }
    </>
  );

SuccessDialog.propTypes = {
  className: HoopsPropTypes.className,
  title: HoopsPropTypes.string,
  topText: HoopsPropTypes.stringOrBool,
  bottomText: HoopsPropTypes.stringOrBool,
  open: HoopsPropTypes.bool,
  onClose: HoopsPropTypes.func,
};
