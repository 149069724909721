import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import classNames from 'classnames';
import {BodyText, HeadingText} from '../Text';
import {Row} from '../Layout';
import {ModalDialog} from './ModalDialog';

registerGlobalStyle('.attention-confirm-dialog', (theme) => ({
  '.MuiDialog-paper': {minWidth: 350},
  '.text-body': {
    fontSize: '1rem',
    padding: theme.spacing(0, 13, 2.5),
    textAlign: 'center',
  },
  'svg': {
    fontSize: '3.6rem',
    color: theme.colors.palette.red,
    margin: theme.spacing(4, 0, -1, 0),
  },
  '&.mild': {svg: {color: theme.colors.palette.orange,}},
}));

export function AttentionConfirmDialog({
                                         className,
                                         cancelText = 'Cancel',
                                         content = '',
                                         okText = 'Ok',
                                         loading,
                                         mild,
                                         title = 'Are you sure?',
                                         warningIcon,
                                         onClose,
                                         onOk,
                                         children
}) {
  return (open &&
    <ModalDialog
      className={classNames([className, 'attention-confirm-dialog', mild && 'mild'])}
      okText={okText}
      cancelText={cancelText}
      loading={loading}
      onClose={onClose}
      onOk={onOk}
    >
      {warningIcon &&
        <Row justifyCenter>
          <WarningIcon/>
        </Row>
      }
      <HeadingText x20 center>{title}</HeadingText>
      {children &&
        children
      }
      {!children &&
        <BodyText>
          {content}
        </BodyText>
      }
    </ModalDialog>
  );
}

AttentionConfirmDialog.propTypes = {
  className: HoopsPropTypes.className,
  cancelText: HoopsPropTypes.string,
  content: HoopsPropTypes.string,
  loading: HoopsPropTypes.bool,
  mild: HoopsPropTypes.bool,
  okText: HoopsPropTypes.string,
  title: HoopsPropTypes.string,
  warningIcon: HoopsPropTypes.bool,
  onClose: HoopsPropTypes.func,
  onOk: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
