import * as React from 'react';
import {Grid, Link, Typography} from '@mui/material';
import {
  ActivityContactRenderer,
  ActivityCurrencyRenderer,
  ActivityCustomerRenderer,
  ActivityDateRenderer,
  ActivityEmailRenderer,
  ActivitySmsRenderer,
  ActivityStatusRenderer,
  ActivityTab,
  ActivityTextRenderer,
  ActivityUserRenderer,
  ActivityValueRenderer,
  invoiceRenderers,
} from '../../../componentsHoops';
import {useGetViews} from '../../../hooks/api';
import {useSelector} from 'react-redux';
import {asCurrencyStringCommaSeparated} from '../../../utils';

const renderers = {
  ...invoiceRenderers,

  CREATE: {renderer: true, heading: ({entityTypeName}) => `Created the ${entityTypeName || 'Quote'}`},
  'jobs.CREATE': {renderer: true, heading: ({entityTypeName}) => `Converted the ${entityTypeName || 'Quote'} to a Job`},
  UPDATE: {
    heading: ({entityTypeName, changes, activity}) => {
      if (changes.documentType) {
        if (changes.documentType[0] === 'presentation' && changes.documentType[1] === 'quoteV2' && activity.user === 'Customer') {
          return 'Checked Out';
        } else {
          return `Converted the ${changes.docTypeName?.[0] || 'Classic Quote'} to a ${changes.docTypeName?.[1] || 'Quote'}`;
        }
      } else if (changes.salesDocCount) {
        return 'Checked Out';
      }
      return `Edited the ${entityTypeName || 'Quote'}`;
    }
  },
  EMAIL_SENT: {renderer: ActivityEmailRenderer, heading: ({entityTypeName}) => `Sent the ${entityTypeName || 'Quote'} by email`},
  SMS_SENT: {renderer: ActivitySmsRenderer, heading: ({entityTypeName}) => `Sent the ${entityTypeName || 'Quote'} by SMS`},
  STATUS: {renderer: ActivityStatusRenderer, heading: ({entityTypeName}) => `Updated the Status of the ${entityTypeName || 'Quote'}`, expand: true},

  'quotes.viewed': {renderer: QuoteViewedRenderer, heading: ({entityTypeName}) => `Viewed the ${entityTypeName || 'Quote'}`, expand: true},
  'quotes.onlinePaymentStatus': {renderer: ActivityTextRenderer, heading: 'Made an online payment', group: true, title: 'Online payment status'},
  'quotes.onlinePaymentAmount': {renderer: ActivityCurrencyRenderer, title: 'Online Payment amount'},
  'quotes.stripeInvoiceId': {renderer: StripeInvoiceRenderer, heading: 'Accepted an online invoice', group: true, title: 'Stripe invoice'},
  'quotes.stripeInvoiceAmount': {renderer: ActivityCurrencyRenderer, title: 'Invoice amount'},

  'quotes.customReference': {renderer: ActivityTextRenderer, title: 'Reference Number'},
  'quotes.description': {renderer: ActivityTextRenderer, title: 'Description'},
  'quotes.leadSource': {renderer: ActivityTextRenderer, title: 'Lead Source'},
  'quotes.deadlineAt': {renderer: ActivityDateRenderer, title: 'Deadline'},
  'quotes.contactId': {renderer: ActivityContactRenderer, title: 'Contact'},
  'quotes.customerId': {renderer: ActivityCustomerRenderer, title: 'Customer'},
  'quotes.quoteOwnerId': {renderer: ActivityUserRenderer, title: 'Owner'},
  'quotes.subTotal': {renderer: SalesDocSubTotalRenderer, title: 'Subtotal'},
  'quotes.taxTotal': {renderer: ActivityCurrencyRenderer, title: 'Tax'},
  'quotes.total': {renderer: ActivityCurrencyRenderer, title: 'Total'},
};

export const SalesDocActivityTab = ({quote}) => {
  const {data: {views: viewData}, isLoading: isViewsLoading} = useGetViews('salesdocs');

  return (<ActivityTab entity={quote} renderers={renderers} viewState={{fields: viewData?.fields, isViewsLoading}}/>);
};

function QuoteViewedRenderer({change}) {
  return (
    <Typography variant='body2'>{change[1] === 1 ? 'Viewed 1 time' : `Viewed ${change[1]} times`}</Typography>
  );
}

function StripeInvoiceRenderer({fieldPath, title, change}) {
  return (
    <>
      <Typography variant='caption'>{title}</Typography>
      <Grid className='change-item text' container key={fieldPath} direction='row' gap={1.5}>
        <Typography variant='body2' className={change[0] == null ? 'nothing' : ''}>{change[0]}</Typography>
        <span>→</span>
        <Link variant='body2' className={change[1] == null ? 'nothing' : ''} target='blank' href={`https://dashboard.stripe.com/invoices/${change[1]}`}>
          View Invoice
        </Link>
      </Grid>
    </>
  );
}

function SalesDocSubTotalRenderer({change, title, ...props}) {
  const currencySymbol = useSelector((state) => state?.authReducer.userData.company.currencySymbol);

  if (props.activity.changes.salesDocCount) {
    title = 'Running total';
  }

  const currencyChange = [
    change[0] != null ? `${currencySymbol}${asCurrencyStringCommaSeparated(change[0])}` : undefined,
    change[1] != null ? `${currencySymbol}${asCurrencyStringCommaSeparated(change[1])}` : undefined
  ];
  return (
    <>
      {props.activity.changes.salesDocCount &&
        <>
          <Typography variant='caption'>Value</Typography>
          <Typography variant='body2'>{`${currencySymbol}${asCurrencyStringCommaSeparated(change[1] - change[0])}`}</Typography>
        </>
      }
      <ActivityValueRenderer change={currencyChange} title={title} {...props}/>
    </>
  );
}
