import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useWatch} from '../../hooks';
import * as Cells from './HoopsPowerGrid/Cells';
import {JobsActionMenu} from './JobActionMenu';
import {DeleteColumnConfirmation} from './Views';
import {PageContext} from '../../componentsOld/PageContext';
import {JobPage} from '../JobPage/JobPage';
import {HoopsPowerGrid, useGridColumnBuilder} from './HoopsPowerGrid';
import {SlidingDrawer} from '../../componentsLib/Popovers';
import {SalesDocPage} from '../SalesDoc/Pages/SalesDocPage';
import {useHistory, useParams} from 'react-router';
import {LinkCell} from '../../componentsLib/PowerGrid';
import {SalesDoc} from '../SalesDoc/Models/SalesDoc';

const jobsColumns = {
  'number': {width: 133, filters: ['equals'], sort: -1, hideable: false, align: 'center', editable: false},
  'digest.quote.number': {filters: ['equals', 'isEmpty', 'isNotEmpty'], sort: -1, align: 'center', editable: false},
  'digest.invoice._id': {
    width: 180, filters: ['isAnyOf', 'isEmpty', 'isNotEmpty'], type: 'singleSelect',
    valueOptions: Cells.AccountingStatusCell.statusOptions,
    renderCell: (params) => <Cells.AccountingStatusCell invoiceData={params.row?.digest?.invoice} />,
    valueGetter({row}) {
      return Cells.getAccountStatusLabel(row?.digest?.invoice)?.label;
    }
  },
  'digest.quote.stripeInvoiceId': {width: 180, filters: ['isEmpty', 'isNotEmpty'], align: 'center', renderCell: Cells.StripeInvoiceCell},
  'customer.name': {filters: ['contains'], editable: false, renderCell: Cells.CustomerCell,},
  'deadlineAt': {purpose: 'dueDate'},
  'actions': {}
};

export const JobsBoard = ({viewState, jobsState}) => {
  const history = useHistory();
  const {jobId, salesDocNumber} = useParams();
  const {saveView} = useContext(PageContext);

  const handleCloseDrawer = useCallback(() => {
    history.push('/jobs');
  }, [history]);

  // If anything changes in the view state that will affect the data that is loaded we
  // update the parameters and possibly reload the data
  useWatch(() => {
    jobsState.updateFetchParams(viewState.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.currentView, viewState.state.filters, viewState.state.page, viewState.state.pageSize, viewState.state.search, viewState.state.sort]);

  // If the view columns change, we reload the data to get the new columns
  useWatch(async () => {
    await jobsState.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.viewData?.fields?.length, jobsState.refetch]);

  // If a column is deleted we confirm the user really wants to delete it
  const [showDeleteColumnConfirmation, setShowDeleteColumnConfirmation] = useState({show: false});
  const handleDeleteColumn = useCallback((fieldPath, columnName) => {
    setShowDeleteColumnConfirmation({show: true, fieldPath, columnName});
  }, []);
  const handleDeleteColumnConfirmation = useCallback((confirmation) => {
    if (confirmation && showDeleteColumnConfirmation.fieldPath) {
      viewState.deleteColumn(showDeleteColumnConfirmation.fieldPath);
    }
    setShowDeleteColumnConfirmation({show: false});
  }, [viewState, showDeleteColumnConfirmation.fieldPath]);

  // Build the column data, using the static column info and the renderers that need callbacks
  const columnInfo = useMemo(() => ({
    ...jobsColumns,
    number: {
      ...jobsColumns?.number,
      renderCell: (props) => <Cells.LinkCell to={`/jobs/${props.row._id}`} {...props} />
    },
    'digest.quote.number': {
      ...jobsColumns?.['digest.quote.number'],
      renderCell: (props) => {
        if (props.row?.digest?.quote?.documentType === SalesDoc.Type.NEW_QUOTE) {
          return (<LinkCell to={`/jobs/sales/${props.row?.digest?.quote?.number}`} {...props} />);
        } else {
          return (<LinkCell to={`/quotes/${props.row?.digest?.quote?._id}/view`} {...props} />);
        }
      }
    },
    actions: {
      ...jobsColumns?.actions,
      renderCell: (props) => <Cells.ActionsButtonCell job={props.row} MenuComponent={JobsActionMenu} updateData={jobsState.refetch} />,
    },
  }), [jobsState.refetch]);

  const columns = useGridColumnBuilder({fields: viewState.viewData?.fields, columnInfo});

  return (
    <>
      <SlidingDrawer className={'salesboard-drawer job-drawer'} open={jobId != null} onClose={handleCloseDrawer}>
        {jobId != null &&
          <JobPage job={{_id: jobId}} updateJobBoard={jobsState.refetch}/>
        }
      </SlidingDrawer>

      <SlidingDrawer className={'salesboard-drawer salesdoc-drawer'} open={salesDocNumber != null} onClose={handleCloseDrawer}>
        {salesDocNumber != null &&
          <SalesDocPage />
        }
      </SlidingDrawer>

      <HoopsPowerGrid
        columns={columns}
        columnWidths={viewState.state.columnWidths}
        density={viewState.state.density}
        filters={viewState.state.filters}
        hiddenColumns={viewState.state.hidden}
        loading={jobsState.isLoading}
        page={jobsState.fetchParams.page}
        pageSize={viewState.state.pageSize}
        pinnedColumns={viewState.state.pinned}
        rowCount={jobsState.jobs?.total}
        rows={jobsState.jobs?.docs}
        search={viewState.state.search}
        sort={viewState.state.sort}
        viewChanged={viewState.state.isChanged}
        viewIsHome={viewState.isHome}
        allowAddColumns={viewState.allowAddColumns}

        onAddColumn={viewState.addColumn}
        onChangeColumnOrder={viewState.setOrder}
        onChangeColumnWidths={viewState.setColumnWidths}
        onChangeDensity={viewState.setDensity}
        onChangeFilters={viewState.setFilters}
        onChangeHiddenColumns={viewState.setHidden}
        onChangePage={viewState.setPage}
        onChangePageSize={viewState.setPageSize}
        onChangePinnedColumns={viewState.setPinned}
        onChangeSort={viewState.setSort}
        onRenameColumn={viewState.setColumnName}
        onDeleteColumn={handleDeleteColumn}
        onSaveView={saveView}
        onSearch={viewState.setSearch}
      />

      {showDeleteColumnConfirmation?.show &&
        <DeleteColumnConfirmation onClose={handleDeleteColumnConfirmation} columnName={showDeleteColumnConfirmation.columnName} />
      }
    </>
  );
};
