import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {AttentionConfirmDialog} from '../../componentsLib/Popovers';
import {registerGlobalStyle} from '../../theme';
import {useUpdateUser} from '../../hooks/api';
import {useUser} from '../../hooks';
import {client} from '../../servicesOld/GraphQLService';

const MultiSessionContext = createContext(null);
export const useMultiSessionContext = () => useContext(MultiSessionContext);
export const useSessionChanged = () => useContext(MultiSessionContext)?.sessionChanged;

registerGlobalStyle('.session-change-dialog', (theme) => ({
  '.actions.row': {justifyContent: 'center'},
  'p.text-body.text': {
    boxSizing: 'content-box',
    fontSize: theme.fontSize(14),
    maxWidth: 348,
  },
  'button': {textWrap: 'nowrap'},
}));

export function MultiSessionProvider({children}) {
  const [sessionId, setSessionId] = useState(0);
  const {user} = useUser();
  const {update: updateUser, isSaving} = useUpdateUser();

  const sessionChanged = useCallback(() => setSessionId((prev) => prev + 1), []);

  const context = useMemo(() => {
    // This is rather hackish, but there is no other way to get a hook into the graphql system
    client.onSessionChanged = sessionChanged;
    return {
      sessionChanged,
      keepThisSession: async () => {
        await updateUser({id: user._id, user: {activeSession: null}});
        setSessionId(0);
      },
    };
  }, [sessionChanged, updateUser, user._id]);

  return (
    <MultiSessionContext.Provider value={context}>
      {user?._id && sessionId > 0 &&
        <AttentionConfirmDialog
          className={'session-change-dialog'}
          title={'Duplicate Session Detected'}
          content={'Your user account is active on multiple devices. If you choose to work on this device, the other devices will be deactivated.'}
          warningIcon
          cancelText={null}
          okText={'Work on this device'}
          loading={isSaving}
          onOk={context.keepThisSession}
        >
        </AttentionConfirmDialog>
      }
      {children}
    </MultiSessionContext.Provider>
  );
}
