import React from 'react';
import {TitleAndInfoCell} from './TitleAndInfoCell';
import {fullName} from '../../../utils';
import {CellContainer} from './CellContainer';

export function CustomerCell({...props}) {
  return (
    <CellContainer>
      <TitleAndInfoCell title={props.row.customer?.name || (props.row.salesStoreNumber ? 'SalesStore Shopper' : '')} info={fullName(props.row.contact)}/>
    </CellContainer>
  );
}
