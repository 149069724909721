import {SalesDocSummary} from './SalesDocSummary';
import {asCurrencyRounded} from '../../../utils';

export class CartPriceCalculator {
  salesStore;
  itemMap;

  constructor({salesStore, itemMap}) {
    this.salesStore = salesStore;
    this.itemMap = itemMap;
  }

  calculate(cart) {
    const summary = new SalesDocSummary();
    summary.calculateQuantities(cart.items);

    const items = cart.items.map((item) => {
      if (!item.isPlaceholder() && !item.isSurchargeOrDiscount()) {
        const groupQuantity = summary.quantities[item.groupId].quantity;
        const effectiveQuantity = item.isDecoration() ? groupQuantity : item.quantity;
        const storeItem = this.salesStore.getPresentationColumnItem(this.itemMap.getStoreId(item.variantId), groupQuantity);
        return item.copyWith({
          additionalCost: storeItem.additionalCost,
          buyPrice: storeItem.buyPrice,
          buyPriceOverride: true,
          markup: storeItem.markup,
          markupOverride: true,
          quantity: effectiveQuantity,
          sellPrice: storeItem.sellPrice,
          unitPrice: storeItem.unitPrice,
          unitPriceOverride: true,
          totalCost: asCurrencyRounded(storeItem.buyPrice * effectiveQuantity),
          subTotal: asCurrencyRounded(storeItem.unitPrice * effectiveQuantity),
        });
      }
      return item;
    });

    summary.calculateSummary(items);

    if (items.some((item) => item.isSurchargeOrDiscount())) {
      // Now calculate the value of any surcharges and discounts
      const basis = summary.getDocumentSummary().totalRevenue;
      items.filter((item) => item.isSurchargeOrDiscount()).forEach((item) => {
        item.sellPrice = asCurrencyRounded(basis * item.percentage / 100);
        item.unitPrice = item.sellPrice;
        item.buyPrice = item.percentage > 0 ? item.sellPrice : 0;
        item.buyPricePerUnit = item.buyPrice;
        item.totalCost = item.buyPrice;
        item.markup = 0;
        item.subTotal = item.unitPrice;
      });
      summary.calculateSummary(items);
    }

    return {items, summary};
  }
}
