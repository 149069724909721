import {useCallback, useMemo} from 'react';
import {cloneDeep, set} from 'lodash';
import {useListSalesDocs, useUpdateSalesDoc} from '../../../hooks/api';

export function useSalesDocsState({page, pageSize, filters, search, sort, params}) {
  const {data: {salesDocs, total}, isLoading, refetch, updateCache} = useListSalesDocs({page: page + 1, pageSize, filters, search, sort, ...params});
  const {update: updateSalesDocApi, isSaving} = useUpdateSalesDoc();

  // Update the value in the field
  const updateField = useCallback(async ({id, field, value, props}) => {
    const index = salesDocs?.findIndex((row) => row._id === id);
    if (index >= 0) {
      const docs = [...salesDocs];
      const doc = cloneDeep(docs[index]);
      props = props ?? {[field]: value};
      Object.entries(props).forEach(([propField, propValue]) => set(doc, propField, propValue));
      docs[index] = doc;
      updateCache({salesDocs: docs});
    }
    const res = await updateSalesDocApi({id, salesDoc: props});
    await refetch();
    return res;
  }, [salesDocs, updateSalesDocApi, refetch, updateCache]);

  return useMemo(() => ({
    salesDocs: salesDocs?.map((row) => ({id: row._id, ...row})) ?? [],
    updateField,
    isLoading,
    isSaving,
    refetch,
    total: total ?? 0,
  }), [isLoading, isSaving, refetch, salesDocs, total, updateField]);
}
