import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';
import LockIcon from '@material-ui/icons/Lock';
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import {Button} from '../../componentsLib/Basic';
import {Column, Row} from '../../componentsLib/Layout';
import {BodyText, CaptionText, HeadingText} from '../../componentsLib/Text';
import {useSalesDocPaymentIntent} from '../../hooks/api';
import {registerGlobalStyle} from '../../theme';
import {asCurrencyStringCommaSeparated} from '../../utils';
import {HoopsPropTypes} from '../../componentsLib';
import {loadStripe} from '@stripe/stripe-js';

registerGlobalStyle('.pay-online', (theme) => ({
  '#card-element': {
    padding: theme.spacing(2),
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.spacing(1),
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {border: 'none'}
  },
  '.error-message': {
    marginTop: theme.spacing(1),
    color: theme.colors.palette.red,
    lineHeight: 1.2,
    marginBottom: theme.spacing(-3),
  },
  '.payment-charges': {
    rowGap: theme.spacing(.75),
    '.row': {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  '.stripe-message': {
    marginTop: theme.spacing(5),
    'svg': {color: theme.colors.text.highlight},
  }

}));

export const PayOnline = forwardRef(({amountToPay, companyName, feesAndCharges, hidePayButton, invoiceAmount, onError, onSuccess, salesDocToken, stripeConnectedAccount}, ref) => {
  const stripePromiseRef = useRef(loadStripe(process.env.REACT_APP_STRIPE_KEY, {stripeAccount: stripeConnectedAccount}));

  return (
    <Elements stripe={stripePromiseRef.current}>
      <_PayOnline
        amountToPay={amountToPay}
        companyName={companyName}
        feesAndCharges={feesAndCharges}
        hidePayButton={hidePayButton}
        invoiceAmount={invoiceAmount}
        onSuccess={onSuccess}
        onError={onError}
        ref={ref}
        salesDocToken={salesDocToken}
        stripeConnectedAccount={stripeConnectedAccount}
      />
    </Elements>
  );
});

export const _PayOnline = forwardRef(({amountToPay, companyName, feesAndCharges, hidePayButton, invoiceAmount, onError, onSuccess, salesDocToken}, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const {['payment-intent']: paymentIntentApi} = useSalesDocPaymentIntent();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const performPayment = async () => {
    try {
      setIsLoading(true);
      const {intent: clientSecret, link} = await paymentIntentApi({id: salesDocToken}, {successMessage: false, throwOnError: true});
      if (!stripe || !elements) {
        return;
      }
      if (!clientSecret) {
        throw new Error(`This payment can not be processed, please contact ${companyName}`);
      }

      // Have Stripe do the payment
      const {paymentIntent, error: stripeError} = await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: {card: elements.getElement(CardElement)}}
      );

      if (stripeError) {
        setError(stripeError.message);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        onSuccess({invoiceUrl: link});
      }

      if (paymentIntent.status) {
        setError(paymentIntent.status);
      }
    } catch (e) {
      setError(e.response?.data?.message ?? e.message);
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  const canPay = useCallback(() => !stripe || !elements || parseFloat(amountToPay) < 2, [amountToPay, elements, stripe]);

  useImperativeHandle(ref, () => ({
    performPayment,
    canPay,
  }));

  return (
    <>

      <Column gap className={'pay-online'}>
        <Column className={'payment-charges'}>
          {feesAndCharges.length > 0 &&
            <Row justifySpaceBetween>
            <BodyText descriptive>Amount</BodyText>
            <BodyText className={'currency-prefix'}>{asCurrencyStringCommaSeparated(invoiceAmount)}</BodyText>
          </Row>
          }
          {feesAndCharges?.map(({name, amount}, index) => (
            <Row key={index}>
              <BodyText descriptive>{name}</BodyText>
              <BodyText className={'currency-prefix'}>{asCurrencyStringCommaSeparated(amount)}</BodyText>
            </Row>
          ))}
          <Row justifySpaceBetween>
            <HeadingText x16>Total Amount (inc Tax)</HeadingText>
            <HeadingText x24 fit className={'currency-prefix'}>{asCurrencyStringCommaSeparated(amountToPay)}</HeadingText>
          </Row>
        </Column>

        <CardElement
          id='card-element'
          options={{
            style: {
              base: {
                color: '#666',
                fontSize: '16px',
              },
              invalid: {color: '#FF0000'}
            },
            hidePostalCode: true
          }}
        />

        {!hidePayButton &&
          <Button
            navMain
            disabled={isLoading || !stripe || !elements || parseFloat(amountToPay) < 2}
            loading={isLoading}
            onClick={performPayment}
            noWrap
            text={'Process Payment'}
          />
        }

        <Row className={'stripe-message'} alignCenter justifyCenter gap>
          <LockIcon fontSize='small' />
          <BodyText descriptive>Secured by Stripe with bank-level encryption</BodyText>
        </Row>

        {error &&
          <Column justifyContent alignCenter>
            <CaptionText text={'Unable to process payment at this time:'} />
            <BodyText className={'error-message'} text={error} />
          </Column>
        }
      </Column>
    </>
  );
});

PayOnline.propTypes = {
  amountToPay: HoopsPropTypes.number,
  companyName: HoopsPropTypes.string,
  feesAndCharges: HoopsPropTypes.arrayOfObject,
  invoiceAmount: HoopsPropTypes.number,
  salesDocToken: HoopsPropTypes.string,
  hidePayButton: HoopsPropTypes.bool,
  onSuccess: HoopsPropTypes.func,
};
