import {SalesDocSummary} from './SalesDocSummary';

export class GuestPriceCalculator {
  calculate(salesDoc) {
    const summary = new SalesDocSummary();
    summary.calculateQuantities(salesDoc.items);
    summary.calculateSummary(salesDoc.items);

    return {items: salesDoc.items, summary};
  }
}
