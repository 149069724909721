
import {asNumber} from './currency';

// Checks if the parameter is an event, in which case returns the target value, otherwise, it
// assumes the parameter is the actual value and returns it.
export function valueFromEvent(eventOrValue, value) {
  if (value != null) {
    return value;
  }
  if (eventOrValue?.target) {
    if (eventOrValue.target.tagName === 'INPUT' && eventOrValue.target.type === 'checkbox') {
      return eventOrValue.target.checked;
    } else if (eventOrValue.target.tagName === 'INPUT' && eventOrValue.target.type === 'number') {
      return asNumber(eventOrValue.target.value);
    } else if (eventOrValue.target.tagName === 'INPUT' && eventOrValue.target.type === 'radio'
      && (eventOrValue.target.value === 'true' || eventOrValue.target.value === 'false')
    ) {
      return eventOrValue.target.value === 'true';
    } else {
      return eventOrValue.target.value;
    }
  } else if (eventOrValue?.value) {
    return eventOrValue?.value;
  }
  return eventOrValue;
}

// Checks if the parameter is an event, in which case returns the target value, otherwise, it
// assumes the parameter is the actual value and returns it.
export function checkedFromEvent(eventOrValue) {
  if (eventOrValue?.target) {
    if (eventOrValue.target.tagName === 'INPUT' && eventOrValue.target.type === 'radio') {
      return eventOrValue.target.value === 'true';
    } else {
      return eventOrValue.target.checked;
    }
  }
  return eventOrValue;
}

// Checks if the parameter is an event, in which case returns the target value, otherwise, it
// assumes the parameter is the actual value and returns it. String values are coerced to boolean.
export function boolValueFromEvent(eventOrValue) {
  eventOrValue = eventOrValue?.target ? eventOrValue.target.value : eventOrValue;
  if (typeof eventOrValue === 'string') {
    if (eventOrValue === 'true') {
      return true;
    } else if (eventOrValue === 'false') {
      return false;
    }
    return !!eventOrValue;
  }
  return Boolean(eventOrValue);
}

// Checks if the parameter is an event, in which case returns the validity value, otherwise, it
// assumes the parameter is the actual value and returns it. String values are coerced to boolean.
export function validityFromEvent(eventOrValue) {
  if (eventOrValue?.target) {
    return eventOrValue.target.validity.valid;
  }
  return Boolean(eventOrValue);
}

// Checks if the parameter is an event, in which case returns the validity value, otherwise, it
// assumes the parameter is the actual value and returns it.
export function inputNameFromEvent(eventOrValue) {
  if (eventOrValue?.target) {
    return eventOrValue.target.name ?? eventOrValue.target.getAttribute('name');
  } else if (eventOrValue?.name) {
    return eventOrValue?.name;
  }
  return eventOrValue;
}

// Finds the selector element, and returns the index of the selector element in its parent.
// Handy to determine the index of a data field object in a list of such objects.
export function indexOfEventTarget(e, {selector}) {
  const target = selector ? e.target.closest(selector) : e.target;
  return target ? [...target.parentElement.children].indexOf(target) : -1;
}
