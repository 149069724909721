import {Dialog, DialogContent, Grid, Link as MuiLink, Typography} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import DialogActions from '../shared/DialogActions';
import DialogTitle from '../shared/DialogTitle';
import UserRoleSelector from './UserRoleSelector';
import {HoopsTextField} from '../HoopsTextField';
import {HoopsButton} from '../HoopsButton';
import {useUpdateUser} from '../../hooks/api';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import {useDispatch} from 'react-redux';
import EmailTemplateService from '../../servicesOld/EmailTemplateService';

export const UserEditModal = ({open = false, user = {}, title = '', roleSelector = false, onClose = () => null}) => {
  const [modalOpen, setModalOpen] = useState(open);
  const [updatedUser, setUpdatedUser] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const handleClose = () => {
    setFormErrors({});
    onClose(false);
    setModalOpen(false);
    setUpdatedUser({});
  };

  const updateUser = useCallback(({target: {name, value}}) => {
    if (value === '') {
      setFormErrors((prev) => ({...prev, [name]: 'This is a required field'}));
    } else if (name === 'username' && !EmailTemplateService.validateEmail(value)) {
      setFormErrors((prev) => ({...prev, [name]: 'Email is invalid'}));
    } else {
      setFormErrors((prev) => {
        if (prev[name]) {
          const clone = {...prev};
          delete clone[name];
          return clone;
        }
        return prev;
      });
    }

    setUpdatedUser((prev) => {
      if (user[name] === value) {
        const clone = {...prev};
        delete clone[name];
        return clone;
      }
      return {...prev, [name]: value};
    });
  }, [user]);

  const {update: saveUpdatedUser, isSaving} = useUpdateUser({errorMessage: ({error, status}) => status === 409 ? error.response.data.message : null});

  const submit = async () => {
    // api update user
    await saveUpdatedUser({id: user._id, user: updatedUser});
    dispatch(hoopsQuerySetRefetch('user', true));
    handleClose();
  };

  return (
    <Dialog open={modalOpen} fullWidth maxWidth={roleSelector ? 'md' : 'sm'} onClose={handleClose}>
      <DialogTitle onClick={handleClose} disableTypography={true} >
        <h3>{title}</h3>
      </DialogTitle>
      <DialogContent data-intercom-target={'invite-user-screen'} style={{overflowY: 'hidden'}}>
        <Grid container spacing={3}>
          {/* user details */}
          <Grid item xs={12}>
            <Typography color={'textSecondary'} variant={'body1'} gutterBottom>Personal Details</Typography>
            <HoopsTextField
              name='firstName'
              label='First Name'
              defaultValue={user.firstName}
              onChange={updateUser}
              error={formErrors.firstName}
              helperText={formErrors.firstName}
            />
            <HoopsTextField
              name='lastName'
              label='Last Name'
              defaultValue={user.lastName}
              onChange={updateUser}
              error={formErrors.lastName}
              helperText={formErrors.lastName}
            />
            <HoopsTextField
              name='username'
              label='Email'
              defaultValue={user.username}
              onChange={updateUser}
              error={formErrors.username}
              helperText={formErrors.username}
            />
          </Grid>

          {/* edit roles */}
          {roleSelector &&
            <>
              <Grid item xs={12}>
                <Typography color={'textSecondary'} variant={'body1'} gutterBottom>Permission</Typography>
                <UserRoleSelector
                  input
                  initialRole={user.role}
                  onChange={(value) => updateUser({target: {name: 'role', value: value}})}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body1'} gutterBottom>Want the full breakdown of user access?
                  <MuiLink color={'primary'} underline={'none'} href={'http://help.hoopscrm.com/en/articles/5026846-user-roles-permissions'} target='_blank'>
                    &nbsp;Click Here
                  </MuiLink>
                </Typography>
              </Grid>
            </>}
        </Grid>

      </DialogContent>
      <DialogActions >
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <HoopsButton purpose='cancel' onClick={handleClose}>Cancel</HoopsButton>
          </Grid>
          <Grid item>
            <HoopsButton purpose='save' loading={isSaving} onClick={() => submit()} disabled={Object.keys(updatedUser).length < 1 || Object.keys(formErrors).length > 0}>Save</HoopsButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
