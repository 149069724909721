import {get} from 'lodash';
import {capitalizeFirst, splitCamelCase} from '../../utils';
import {replaceAll} from '../../utils/replaceAll';

export function initTemplateSubstitutions(substitutions, group) {
  return Object.entries(substitutions).reduce((acc, [key, subs]) => ({
    ...acc,
    [key]: {
      ...subs,
      key: key,
      field: subs.field ?? key,
      name: subs.name ?? capitalizeFirst(splitCamelCase(key)),
      group: subs.group ?? group,
      fn: subs.fn,
    }
  }), {});
}

export function nestSubstitutions(nesting) {
  return Object.entries(nesting).reduce((nestAcc, [nestKey, substitutions]) => Object.entries(substitutions).reduce((acc, [key, subs]) => ({
    ...acc,
    [key]: {
      ...subs,
      field: `${nestKey}.${subs.field}`,
    }
  }), nestAcc), {});
}

export function doTemplateSubstitution(template, substitutions, fields, removeMissing) {
  const templateSubstitutions = (template ?? '').match(/({{)(.*?)(}})/g);

  if (!templateSubstitutions) {
    return template;
  }

  const substitutionObj = {};
  templateSubstitutions?.forEach((shortcode) => {
    const subst = substitutions[shortcode.replace(/[{}]/g, '')];
    let value = subst?.field && get(fields, subst?.field, '');
    if (value) {
      if (subst.fn) {
        value = subst.fn(value);
      }
      substitutionObj[shortcode] = `${value}`;
    } else if (removeMissing) {
      substitutionObj[shortcode] = '';
    }
  });

  return replaceAll(template, substitutionObj).split('<br>').filter(Boolean).join('<br>');
}

export function removeMissingSubstitutions(template, substitutions) {
  return doTemplateSubstitution(
    template,
    substitutions,
    Object.keys(substitutions).reduce((obj, field) => ({...obj, [field]: `{{${field}}}`}), {}),
    true);
}
