
export const colors = {
  transparent: 'transparent',

  palette: {
    red: '#FC5F65',
    redHover: '#CC2F35',
    redDisabled: '#FEAFB2',
    redMildest: '#FED7D9',
    green: '#22CC96',
    greenHover: '#1EB384',
    greenDisabled: '#91E6CB',
    blue: '#00CCF0',
    blueHover: '#14AADC',
    blueDisabled: '#80E6F8',
    greyLight: '#BDBDBF',
    greyLighter: '#D9DADC',
    greyLightest: '#F3F3F6',
    orange: '#FFBF66',
    orangeContrast: '#FF8B4C',
    orangeLight: '#FFF0DA',
    orangeMild: '#F5DEB3',
    yellow: '#FFBF66',
    purple: '#9366AF',
    black: '#000000',
    white: '#FFFFFF',
    transparent: 'transparent',
  },

  text: {
    main: '#2F323C',
    dark: '#5E6478',
    mediumIcon: '#767A87',
    medium: '#8F9197',
    descriptive: '#8F9197',
    mediumDisabled: '#C7C8C6',
    mediumLight: '#A5A7AC',
    mediumDecorator: '#BDBDBF',
    light: '#E8EAED',
    contrast: '#FFFFFF',
    white: '#FFFFFF',
    warning: '#FFBF66',
    highlight: '#00CCF0',
    highlightDark: '#14AADC',
    highlightLight: '#EAFCFF',
  },

  background: {
    white: '#FFFFFF',
    almostWhite: '#FBFBFB',
    disabled: '#F8F8F8',
    empty: '#FAFAFA',
    paper: '#FFFFFF',
    whiteHover: '#F2FCFE',
    almostWhiteHover: '#EFF9FB',
    hover: '#F2FCFE',
    contrast: {
      main: '#00CCF0',
      medium: '#BFF2FB'
    },
    selected: '#F2F2F2',
    selectedHover: '#E6F0F2',
    contrastDark: '#14AADC',
    grey: {
      light: '#EFEFEF',
      dark: '#2F323C',
    },
    backdropWhite: '#FFFFFFA0',
    invalidBackground: '#FF000008',
    warning: '#FFF0DA',
  },

  border: {
    dark: '#2F323C',
    highlight: '#00CCF0',
    hover: '#000000DA',
    focus: '#00CCF0',
    light: '#00000026',
    lightest: '#0000000C',
    lightestRgb: '#F4F4F4',
    main: '#00000040',
    transparent: 'transparent',
    error: '#FC5F65',
    warning: '#FFBF66',
  },

  alpha: {
    dark: '#00000020',
    darker: '#00000040',
    light: '#FFFFFF20',
    lighter: '#FFFFFF40',
  },
};
