import {useState, useEffect} from 'react';

export const useDeviceDetect = () => {
  const [device, setDevice] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false
  });

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobileRegex = /Android|iPhone|Mobile|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i;
    const tabletRegex = /iPad|Android|Touch/i;

    const isMobile = mobileRegex.test(userAgent);
    const isTablet = !isMobile && tabletRegex.test(userAgent);
    const isDesktop = !isMobile && !isTablet;

    setDevice({
      isMobile,
      isTablet,
      isDesktop
    });
  }, []);

  return device;
};
