import {HttpLink} from 'apollo-link-http';
import {ApolloLink, from} from 'apollo-link';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import fetch from 'unfetch';

export function createGraphQLClient({endpoint = process.env.REACT_APP_GRAPHQL_URI,}) {
  let apolloClient;

  const httpLink = new HttpLink({uri: endpoint});

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({credentials: 'include'});
    return forward(operation);
  });

  const sessionLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      const context = operation.getContext();
      const {response: {headers}} = context;
      if (headers.get('x-session-changed')) {
        if (apolloClient.onSessionChanged) {
          apolloClient.onSessionChanged();
        }
      }
      return response;
    })
  );

  apolloClient = new ApolloClient({
    link: from([authMiddleware, sessionLink, httpLink]),
    fetchOptions: {fetch},
    cache: new InMemoryCache({
      // This is set to false because creating nested inputs can fail with __typeName
      addTypename: false
    }),
    defaultOptions: {
      query: {fetchPolicy: 'no-cache'},
      mutate: {fetchPolicy: 'no-cache'}
    }
  });

  return apolloClient;
}

export const client = createGraphQLClient({});
