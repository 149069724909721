export function getFileNameFromUrl(url) {
  try {
    // Create a new URL object
    const parsedUrl = new URL(url);

    // Get the pathname from the URL
    const pathname = parsedUrl.pathname;

    // Extract the file name from the pathname
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);

    return fileName;
  } catch (e) {
    // Handle invalid URLs
    console.error('Invalid URL:', e);
    return null;
  }
}
