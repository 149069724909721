import {permissionCheck} from './permissionCheck';
import {HoopsPropTypes} from '../../componentsLib';

// Checks access and either show or hides children
export const PermissionBasedContent = ({
  allowedRoles = [],
  allowedSubscriptions = [],
  disallowedSubscriptions = [],
  allowedAppAccess = [],
  children
}) => {
  if (permissionCheck({
    allowedRoles,
    allowedSubscriptions,
    disallowedSubscriptions,
    allowedAppAccess,
  })) {
    if (children) {
      return children;
    }
    return true;
  }
  return false;
};

PermissionBasedContent.propTypes = {
  allowedRoles: HoopsPropTypes.arrayOfString,
  allowedSubscriptions: HoopsPropTypes.arrayOfString,
  disallowedSubscriptions: HoopsPropTypes.arrayOfString,
  allowedAppAccess: HoopsPropTypes.arrayOfString,
  children: HoopsPropTypes.children,
};
