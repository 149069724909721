import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {LicenseInfo} from '@mui/x-license-pro';
import {getCurrentUser} from '../actions/action-types';
import {getCompany} from '../actions/action-types/company';
import {GetUsers as _getUsers, UserLoggedIn} from '../actions/action-types/user';
import {AppReady} from '../actions/app';
import {getBilling, getUpcomingInvoice} from '../actions/billing';
import PublicWrapper from '../componentsOld/auth/login/PublicWrapper';
import IntercomLauncher from '../componentsOld/intercom/intercom';
import {LOGIN_TOKEN_KEY} from '../constants/api';
import {AppRoutes, APP_BASENAME} from './routes/AppRoutes';
import GuestRoutes, {GUEST_BASENAME} from './routes/GuestRoutes';
import {useMountEffect} from '../hooks';
import {asRoundedString} from '../utils';
import {HostedRoutes} from './routes/HostedRoutes';

// MUI X license key
LicenseInfo.setLicenseKey('8ebbc99b3b8fec472190af7c63e5aea6Tz05MzEyNyxFPTE3NTA5MTc4MzEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export function App() {
  const appReady = useSelector((state) => state.appReducer.appReady);
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const isHostedSalesStore = !window.location.hostname.includes('hoopscrm') && !window.location.hostname.includes('localhost');

  console.log('Current timezone offset', asRoundedString(new Date().getTimezoneOffset() / 60, 0, 1));

  useMountEffect(() => {
    const loginTokenName = localStorage.getItem(LOGIN_TOKEN_KEY);
    let hasLoginCookie;
    if (loginTokenName) {
      if (loginTokenName.length > 100) {
        // The old code used to put the actual token in local storage, so if the value is long, it means we have an old
        // login, and we should not move the user to the login screen.
        // TODO: Once everyone is updated, we should remove this
        hasLoginCookie = true;
      } else if (document.cookie.split(/;\s*/).some((cookie) => cookie.startsWith(`${loginTokenName}=`))) {
        hasLoginCookie = true;
      }
    }
    if (hasLoginCookie) {
      dispatch(getCurrentUser())
        .then(() => {
          dispatch(UserLoggedIn(true));
          dispatch(AppReady);
          dispatch(getCompany());
          dispatch(_getUsers());
          dispatch(getBilling());
          dispatch(getUpcomingInvoice());
        });
    } else {
      dispatch(AppReady);
    }
  });

  return (!!appReady && (
    <BrowserRouter>
      <Switch>
        {!isHostedSalesStore &&
          <Route path={GUEST_BASENAME}>
            <GuestRoutes />
          </Route>
        }
        {auth.isLoggedIn && !isHostedSalesStore &&
          <Route path={APP_BASENAME}>
            <IntercomLauncher />
            <AppRoutes />
          </Route>
        }
        {!auth.isLoggedIn && !isHostedSalesStore &&
          <Route path={'/'}>
            <IntercomLauncher />
            <PublicWrapper />
          </Route>
        }
        {isHostedSalesStore &&
          <Route path='/'>
            <HostedRoutes />
          </Route>
        }
      </Switch>
    </BrowserRouter>
  ));
}
