
const _1KB = 1024;
const _1Meg = 1024 * 1024;
const _1Gig = 1024 * _1Meg;

export const MaxFileSize = {
  Max60KB: _1KB * 60,
  Max2Meg: _1Meg * 2,
  Max10Meg: _1Meg * 10,
  Max200Meg: _1Meg * 200,
  Max10Gig: _1Gig * 10,
};

const MaxFileSizeNames = {
  [MaxFileSize.Max60KB]: '60 KB',
  [MaxFileSize.Max2Meg]: '2 MB',
  [MaxFileSize.Max10Meg]: '10 MB',
  [MaxFileSize.Max200Meg]: '200 MB',
  [MaxFileSize.Max10Gig]: '10 GB',
};

export function filterFiles(files, {maxSize, multi, accept}, snack) {

  const acceptedTypes = accept.split(',') ?? [];

  if (maxSize == null) {
    maxSize = MaxFileSize.Max2Meg;
  }

  return files.reduce((acc, file) => {
    if (file.size > maxSize) {
      snack.showSnackbarError(`Unable to upload ${file.name}. Files may not exceed ${MaxFileSizeNames[maxSize]}.`);
    } else if (accept && (!acceptedTypes.includes(file.type.split('/')[0] + '/*') && !acceptedTypes.includes(file.type))) {
      snack.showSnackbarError(`Unable to upload ${file.name}. It should be of type ${accept.split('/')[0]}.`);
    } else if (acc.length > 1 && !multi) {
      snack.showSnackbarError(`Unable to upload ${file.name}. Only one file may be uploaded at a time.`);
    } else {
      acc.push(file);
    }
    return acc;
  }, []);
}
