import {registerGlobalStyle} from './HoopsGlobalStyles';

// Reset styles
registerGlobalStyle('*', () => ({fontFamily: 'inherit',}));

// Globally used styles

registerGlobalStyle('.paper', (theme) => ({
  padding: theme.spacing(2),
  color: theme.colors.text.main,
  backgroundColor: theme.colors.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows.paper
}));

registerGlobalStyle('.popover-elevation, .MuiPaper-root.MuiPaper-elevation', (theme) => ({boxShadow: theme.shadows.popoverElevation}));

registerGlobalStyle('.hover-elevation', (theme) => ({boxShadow: theme.shadows.hoverElevation}));

registerGlobalStyle('.input-outline', (theme) => ({
  borderRadius: theme.shape.borderRadius,
  border: '1px solid transparent',
  borderColor: theme.colors.border.main,
  transition: theme.transitions.out.borderColor,
  padding: theme.spacing(1, 1.75),
  fontSize: theme.typography.fontSize,
  outline: 'none',
  '&.input-outline:hover': {
    borderColor: theme.colors.border.hover,
    transition: theme.transitions.in.borderColor,
  },
  '&.input-outline:focus-within, &.input-outline:focus': {
    borderColor: theme.colors.border.focus,
    transition: theme.transitions.in.borderColor,
  },
  '&.input-outline:invalid, &.input-outline.invalid': {borderColor: theme.colors.border.error,},
  'input': {
    fontSize: 'inherit',
    padding: 0,
    border: 'none',
    '&:focus': {outline: 'none'},
  },
  '&:has(>*)': {display: 'flex'},
  '.suffix': {
    color: theme.colors.text.mediumIcon,
    display: 'flex',
    height: 0,
    minHeight: 0,
    alignSelf: 'center',
    alignItems: 'center',
  },
}));

registerGlobalStyle('.currency-prefix', (theme) => ({
  '&::before': {content: 'var(--currencyContent)'},
  '&.text-body::before': {paddingRight: theme.spacing(.5)},
}));

registerGlobalStyle('.MuiTooltip-tooltip a', () => ({
  textDecoration: 'underline',
  color: 'inherit',
}));
